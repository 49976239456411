import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { SiAdobeacrobatreader } from 'react-icons/si'

import LearnNav from '../../components/LearnNav'
import './learningDetail.scss'

const LearningDetail = ({ path, data }) => {
  const { learningPath, allTutorials: tutorials } = data
  const tutorialSet = []

  learningPath.frontmatter.tutorials.forEach((tut) => {

    const currentTutorial = tutorials.nodes.filter(
      elem => elem.frontmatter.path.substring(1) === tut
    )

    tutorialSet.push({
      path: tut,
      short_title: currentTutorial[0].frontmatter.short_title,
      learningPath: learningPath.frontmatter.path.substring(1)
    })
  })

  const nextPath = tutorialSet[0].path
  const downloadFileExists = learningPath.frontmatter.download_file !== null
  
  return (
    <div className={`page--learning-path-container`}>
      <Helmet title={`Learning Path - ${learningPath.frontmatter.title} | Couchbase Developer Portal`} />
      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row">
        <div className="learn-nav-container">
          <LearnNav learningPath={learningPath} path={path} tutorialSet={tutorialSet} />
        </div>
        <div className="learn-path-container">
          <div className="flex flex-row">
            <div className={`${downloadFileExists ? 'w-4/6' : 'w-full'}`}>
              <h1>{learningPath.frontmatter.title}</h1>
            </div>
          { downloadFileExists 
            ? <div className={`w-2/6 text-right`}>
              <a className="cb-black-button-large" href={learningPath.frontmatter.download_file} alt={`Download ${learningPath.frontmatter.title} PDF`}>
                <SiAdobeacrobatreader size={18} style={{ display: 'inline' }} className="mr-2" />PDF
              </a>
            </div>
            : null
          }
          </div>
          <div
            className={`markdown-innerhtml`}
            dangerouslySetInnerHTML={{ __html: learningPath.html }}
          />
          <div className="next-tutorial-in-learning-path">
            <hr className="h-1 mt-4" />
            <div className="mt-4 mb-4 text-gray-600 text-right">
              <Link className="cb-red-button" exact="true" activeClassName="active"
                alt="Next Step in Learning Path"
                to={`/${nextPath}?learningPath=${learningPath.frontmatter.path.substring(1)}`}
              >Next Step</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearningDetail

export const learningPathTemplateQuery = graphql`
  query LearningPathByPath($path: String!) {
    learningPath: markdownRemark(frontmatter: { path: { eq: $path } }, ) {
      id
      html
      frontmatter {
        content_type
        description
        download_file
        length
        path
        related_paths
        short_title
        tags
        technology
        title
        tutorials
      }
    }
    allTutorials: allMarkdownRemark(filter: { frontmatter: { content_type: { in: ["tutorial", "quickstart"] }, } }) {
      nodes {
        frontmatter {
          title
          short_title
          path
        }
      }
    }
  }
`
