import React from 'react'
import { Link } from 'gatsby'
import '../styles/modules/learn-nav.scss'

const LearnNav = ({ learningPath, path, tutorialSet }) => {
  return (
    <nav className="learn-nav">
      <ul>
        <li className={`nav-list-item top ${learningPath.frontmatter.path === path ? 'active': ''}`} key={`${learningPath.frontmatter.path}-0`}>
          <Link className="nav-link" to={`${learningPath.frontmatter.path}`}>
            {learningPath.frontmatter.short_title}
          </Link>
        </li>
        {tutorialSet.map((tut, idx) => {
          return (
            <li className={`nav-list-item sub ${tut.path === path ? 'active': ''}`} key={`${tut.path}-${idx+1}`}>
              <Link className="nav-link" to={`/${tut.path}?learningPath=${tut.learningPath}`}>
                {tut.short_title}
              </Link>
            </li>
          )  
        })}
      </ul>
    </nav>
  )
}

export default LearnNav
